import { EmailAddress } from '#/components/pages/PensionProvision/onboarding/steps/registration/first-step';
import CoreForm from '#/components/pages/PensionProvision/onboarding/steps/registration/core-form';

export interface Topic {
  value: string;
  title: string;
  panelURL?: string;
  landingURL?: string;
}

export const coreQuestions = [
  {
    id: 1,
    questionKey: 'provision_for',
    answers: [{ value: 'self' }, { value: 'family' }],
    children: { content: () => <CoreForm /> },
  },
  {
    id: 2,
    questionKey: 'have_real_estate',
    answers: [{ value: 'yes' }, { value: 'no' }],
    children: { content: () => <EmailAddress /> },
  },
];

export const GENDER_OPTIONS = [
  {
    value: 'M',
    label: 'male',
  },
  {
    value: 'F',
    label: 'female',
  },
];

export const CIVIL_STATUS_OPTIONS = [
  {
    code: 'single',
  },
  {
    code: 'married',
  },
  {
    code: 'divorced',
  },
  {
    code: 'widowed',
  },
  {
    code: 'registered_partnership',
  },
];

export const PROVISION_TOPICS: Topic[] = [
  {
    value: 'vorsorgeauftrag',
    title: 'global.services.powerOfAttorney',
    landingURL: '/vorsorgeauftrag',
    panelURL: '/dashboard/vorsorgeauftrag',
  },
  {
    value: 'patientenverfuegung',
    title: 'global.services.livingWill',
    landingURL: '/patientenverfuegung',
    panelURL: '/dashboard/patientenverfuegung',
  },
  {
    value: 'testament',
    title: 'global.services.lastWill',
    landingURL: '/testament',
    panelURL: '/dashboard/testament',
  },
  {
    value: 'ehevertrag',
    title: 'global.services.marriageContract',
    landingURL: '/ehevertrag',
    panelURL: '/dashboard/ehevertrag',
  },
  {
    value: 'digitaler-nachlass',
    title: 'global.services.digitalFootprint',
    landingURL: '/digitaler-nachlass',
    panelURL: '/dashboard/digitaler-nachlass',
  },
  {
    value: 'vermoegens-und-sachregister',
    title: 'global.services.registerAssets',
    landingURL: '/vermoegens-und-sachregister',
    panelURL: '/dashboard/vermoegens-und-sachregister',
  },
  // {
  //   value: 'finanzberater',
  //   title:
  //     'pensionProvision.onboarding.stepThree.provisionTopicsOptions.financialAdvise',
  // },
  // {
  //   value: 'rechtsberater',
  //   title:
  //     'pensionProvision.onboarding.stepThree.provisionTopicsOptions.legalAdvise',
  // },
];

export const LIFE_SITUATIONS: Topic[] = [
  {
    value: 'marriage',
    title:
      'pensionProvision.onboarding.stepThree.lifeSituationsOptions.marriage',
    landingURL: '/vorsorge/ehevertrag',
  },
  {
    value: 'birthOfChild',
    title:
      'pensionProvision.onboarding.stepThree.lifeSituationsOptions.birthOfChild',
    landingURL: '/vorsorge/geburt',
  },
  {
    value: 'purchaseOfRealEstate',
    title:
      'pensionProvision.onboarding.stepThree.lifeSituationsOptions.purchaseOfRealEstate',
    landingURL: '/vorsorge/kauf-einer-immobilie',
  },
  {
    value: 'enteringRetirement',
    title:
      'pensionProvision.onboarding.stepThree.lifeSituationsOptions.enteringRetirement',
    landingURL: '/vorsorge/pensionieren',
  },
  {
    value: 'illnes',
    title: 'pensionProvision.onboarding.stepThree.lifeSituationsOptions.illnes',
    landingURL: '/vorsorge/krankheit',
  },
  {
    value: 'deathOfARelative',
    title:
      'pensionProvision.onboarding.stepThree.lifeSituationsOptions.deathOfARelative',
    landingURL: '/vorsorge/tod-eines-verwandten',
  },
  {
    value: 'foundingACompany',
    title:
      'pensionProvision.onboarding.stepThree.lifeSituationsOptions.foundingACompany',
    landingURL: '/vorsorge/firmengrundung',
  },
  {
    value: 'divorce',
    title:
      'pensionProvision.onboarding.stepThree.lifeSituationsOptions.divorce',
    landingURL: '/vorsorge/scheidung',
  },
];
